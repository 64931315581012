import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable, filter, map, take, timer } from 'rxjs';
import { CommonsModule } from '../core/commons/commons.module';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { BookState } from '../core/store/book/book.state';
import { StorageService } from '../core/services/storage.service';

export enum ErrorType {
  AccessDenied = 'access_denied',
  UnexpectedError = 'unexpected_error',
  ForbiddenAccess = 'forbidden_access'
}

type AccessError = {
  type: ErrorType;
  title: string;
  message: string;
};

@Component({
  selector: 'liv-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  standalone: true,
  imports: [CommonModule, CommonsModule]
})
export class AuthComponent implements OnInit {
  readonly MAX_TIMER_IN_SECONDS = 30;

  timerValue: number;

  readonly portfolioUrl = `${environment.portalLiv}/portfolio`;
  readonly error$: Observable<AccessError>;

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private storageService: StorageService
  ) {
    this.error$ = this.route.queryParamMap.pipe(
      filter((params) => !!params.get('error')),
      map((params) => {
        const _error = params.get('error');
        if (_error === ErrorType.UnexpectedError) {
          return {
            type: ErrorType.UnexpectedError,
            title: 'Ops, algo deu errado!',
            message: ''
          };
        }
        if (_error === ErrorType.ForbiddenAccess) {
          return {
            type: ErrorType.ForbiddenAccess,
            title: 'Ops, acesso negado!',
            message: 'Parece que você não tem acesso a este portfólio.'
          };
        }

        return {
          type: ErrorType.AccessDenied,
          title: 'Acesso negado!',
          message: 'Você não tem permissão para acessar esta página.'
        };
      })
    );
  }

  ngOnInit(): void {
    timer(0, 1000) // Emite um valor a cada segundo (1000ms)
      .pipe(take(this.MAX_TIMER_IN_SECONDS + 1)) // Emite apenas 11 valores (0 a 10)
      .subscribe((value) => {
        this.timerValue = this.MAX_TIMER_IN_SECONDS - value; // Atualiza o valor do timer
      })
      .add(() => {
        this.redirectToPortfolios(); // Redireciona para a página de portfólios
      });
  }

  redirectToPortfolios(): void {
    const bookId = this.store.selectSnapshot(BookState.bookId);

    const url = new URL(this.portfolioUrl);

    if (bookId) {
      url.searchParams.append('book_id', String(bookId));
    }

    window.location.href = encodeURI(url.href);
  }
}
