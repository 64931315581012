<div class="auth-container">
  <div class="auth-box">
    <div class="auth-content" *ngIf="error$ | async as error">
      <span class="auth-emoji">😕</span>
      <h1 class="error-title">{{ error.title }}</h1>
      <p class="error-message" *ngIf="error.message">{{ error.message }}</p>
      <p>
        Mas não se preocupe! <br />
        Você será redirecionado em
        <strong>{{ timerValue }} segundos</strong> para seus portfólios.
      </p>
      <p class="auth-sm-p">
        Caso não seja redirecionado, clique no botão abaixo.
      </p>

      <liv-button
        buttonText="Meus portfólios"
        (buttonClick)="redirectToPortfolios()"
      ></liv-button>
    </div>

    <div class="auth-logo">
      <img src="assets/images/logo-liv.png" alt="Logo Liv" />
    </div>
  </div>
</div>
